@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply scroll-smooth;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  width: 100vw;
}
body {
  overflow-x: hidden;
  overflow-y: scroll;
}

#__next {
  height: 100%;
}

.cl-profilePage > div {
  flex-wrap: wrap;
}

button[aria-label="Invite"] {
  @apply bg-slate-900;
}
button[aria-label="Invite"]:hover {
  @apply bg-slate-700;
}

/* Any input-linked labels get a pointer */
label[for] {
  cursor: pointer;
}

.break-word {
  overflow-wrap: break-word;
}